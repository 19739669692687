import {INPUT_TYPES} from 'generators/input/GeneratedInput';
import * as yup from 'yup';

export const LOGIN_FIELDS = [
    {
        name: 'email',
        label: 'Email',
        type: 'email',
        inputType: INPUT_TYPES.TEXT,
        margin: 'normal',
        validations: yup.string().email('Неверный формат email').required('Поле должно быть заполнено'),
        autoComplete: 'email',
        autoFocus: true
    },
    {
        name: 'password',
        label: 'Пароль',
        type: 'password',
        inputType: INPUT_TYPES.TEXT,
        margin: 'normal',
        validations: yup.string().required('Поле должно быть заполнено'),
        autoComplete: 'current-password'
    }
];