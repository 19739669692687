import React, {useState} from 'react';
import {Avatar, Box, Container, Grid, Typography} from '@mui/material';
import {useMutation} from '@apollo/client';
import {LOGIN_USER} from 'graphql/auth/loginUser';
import {useForm} from 'react-hook-form';
import generateValidators from 'generators/validation/generateValidators';
import GeneratedInput from 'generators/input/GeneratedInput';
import Link from 'components/Link';
import {yupResolver} from '@hookform/resolvers/yup';
import Logo from 'public/logo.svg';
import {useRouter} from 'next/router';
import cookies from 'js-cookie';
import moment from 'moment';
import {LoadingButton} from '@mui/lab';
import {LOGIN_FIELDS} from 'views/auth/login/fields';


const Login = () => {
    const {query, push} = useRouter();
    const [loading, setLoading] = useState(false);
    const {control, handleSubmit, setError} = useForm({
        resolver: yupResolver(generateValidators(LOGIN_FIELDS))
    });
    const [loginUser] = useMutation(LOGIN_USER);

    const onSubmit = (data) => {
        setLoading(true);
        const {email, password} = data;
        loginUser({
            variables: {email, password}
        }).then(res => {
            const accessToken = res.data?.loginUser?.accessToken;
            cookies.set('accessToken', accessToken, {expires: moment().add(30, 'days').toDate()});
            push(query?.url || '/').then(() => setLoading(false));
        }).catch(err => {
            setError('password', {type: 'invalid', message: 'Неверный email или пароль'});
            setError('email', {type: 'invalid'});
            setLoading(false);
        });
    };

    return (
        <Container component='main' maxWidth='xs'>
            <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Avatar sx={{m: 1, bgcolor: 'transparent'}}>
                    <img src={Logo} alt={'phoenix-logo'} style={{width: '100%'}} />
                </Avatar>
                <Typography variant='h4'>Войти</Typography>
                <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate sx={{mt: 1}}>
                    {LOGIN_FIELDS.map(input => <GeneratedInput {...{input, control}} />)}
                    <LoadingButton loading={loading} type='submit'
                                   fullWidth variant='contained'
                                   sx={{mt: 3, mb: 2}}>
                        Войти
                    </LoadingButton>
                    <Grid container>
                        <Grid item xs>
                            <Link href={'/auth/reset-password'} variant='body2' underline={'none'}>
                                Забыли пароль?
                            </Link>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <Link href={"/auth/register"} variant="body2" underline={'none'}>*/}
                        {/*        Еще не зарегистрированы?*/}
                        {/*    </Link>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export default Login;
